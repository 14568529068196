import React from "react";
import { Helmet } from "react-helmet-async";
import useLocale from "../../util/i18n";
import { getConfig, saveConfig } from "../../api/core";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import { CancelablePromise } from "../../util/client";
import { useCrumbs } from "../../component/breadcrumbs";

const EXCEPTIONS = { 'inventory/skip': 'No Label', 'inventory/combine': 'Combined Labels', 'inventory/confirm': 'Confirm Needed', 'inventory/separate': 'Separated Labels' };
const init: DataType = { 'inventory/skip': [], 'inventory/combine': [], 'inventory/confirm': [], 'inventory/separate': [] };
type ConfigRow = {
    sku: Array<string>;
    match?: boolean;
    divide?: number | Array<number>;
    display?: string;
} | Array<string>;
type DataType = Record<keyof typeof EXCEPTIONS, Array<ConfigRow>>;

export default function ConfigInventory() {
    let [data, setData] = React.useState<DataType>({...init});
    let [__] = useLocale();
    let [setCrumbs] = useCrumbs();
    const debounce = React.useRef<number | null>(null);
    const last = React.useRef<CancelablePromise<any> | null>(null);
    const saving = React.useRef((data: DataType) => {
        if (debounce.current) {
            window.clearTimeout(debounce.current);
        }
        let f = () => {
            debounce.current = null;
            last.current = null;
        };
        debounce.current = window.setTimeout(() => {
            if (last.current) {
                last.current.cancel();
            }
            let params = [];
            for (let path in data) {
                params.push({
                    path, value: JSON.stringify(data[path as keyof typeof EXCEPTIONS])
                });
            }
            (last.current = saveConfig(params)).then(f, f);
        }, 1000);
    });
    let doSave = React.useCallback((type: keyof typeof EXCEPTIONS, row: number, key: 'sku' | 'divide' | 'display' | 'match', e: boolean | React.FocusEvent<HTMLTableCellElement>) => {
        if (Array.isArray(data[type][row])) {
            data[type][row] = {
                sku: data[type][row]
            };
        }
        let value: any = typeof e === 'boolean' ? e : e.target.innerHTML;
        if (key === 'divide') {
            let parts = [];
            for (let part of value.split(',')) {
                parts.push(parseFloat(part));
            }
            value = parts;
        } else if (key === (type === 'inventory/separate' ? 'display' : 'sku')) {
            value = value.split(/\s*[\,\uff0c]\s*/);
        }
        if (value === data[type][row][key]) {
            return;
        }
        //@ts-ignore
        data[type][row][key] = value;
        setData({ ...data });
        saving.current(data);
    }, [setData, data]);
    let doAdd = React.useCallback((type: keyof typeof EXCEPTIONS, e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        e.preventDefault();
        data[type].push([]);
        setData({ ...data });
    }, [data, setData]);
    let doDel = React.useCallback((type: keyof typeof EXCEPTIONS, row: number, e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        e.preventDefault();
        data[type].splice(row, 1);
        setData({ ...data });
        saving.current(data);
    }, [data, setData]);
    React.useEffect(() => {
        getConfig('inventory/%').then(response => {
            let configs: DataType = {...init};
            for (let i in response) {
                if (i in EXCEPTIONS) {
                    configs[i as keyof typeof EXCEPTIONS] = JSON.parse(response[i]);
                }
            }
            setData(configs);
        });
    }, []);
    React.useEffect(() => {
        setCrumbs(['Inventory Settings']);
    }, []);
    return (
        <>
            <Helmet>
                <title>{__('Inventory Settings')}</title>
            </Helmet>
            {Object.keys(data).map(e => {
                return (
                    <MDBTable key={e} striped hover className="inventory-settings caption-top">
                        <caption>
                            {__(EXCEPTIONS[e as keyof typeof EXCEPTIONS])}
                            <button type="button" onClick={doAdd.bind(null, e as keyof typeof EXCEPTIONS)}><span className="fa fa-plus" /></button>
                        </caption>
                        <MDBTableHead>
                            <tr>
                                <th>SKU</th>
                                <th className="w-1 text-center text-nowrap">{__('Match')}</th>
                                {e === 'inventory/skip' ? null : (
                                    <th className="w-1 text-center text-nowrap">{__(e === 'inventory/confirm' || e === 'inventory/separate' ? 'Times' : 'Divide')}</th>
                                )}
                                <th className={(e === 'inventory/confirm' || e === 'inventory/separate' ? '' : 'w-1 ') + 'text-center text-nowrap'}>{__('Display')}</th>
                                <th className="w-1 text-center text-nowrap">{__('Delete')}</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {data[e as keyof typeof EXCEPTIONS].length > 0 ? (
                                data[e as keyof typeof EXCEPTIONS].map((item, i) => {
                                    let data = Array.isArray(item) ? { sku: item } : item;
                                    return (
                                        <tr key={e + '-' + i}>
                                            <td contentEditable="plaintext-only" suppressContentEditableWarning onBlur={doSave.bind(null, e as keyof typeof EXCEPTIONS, i, 'sku')}>
                                                {Array.isArray(data.sku) ? data.sku.join(',') : (data.sku || '')}
                                            </td>
                                            <th onClick={doSave.bind(null, e as keyof typeof EXCEPTIONS, i, 'match', !data.match)} className="w-1 text-center">{__(data.match ? 'Match' : 'Contains')}</th>
                                            {e === 'inventory/skip' ? null : (
                                                <td contentEditable="plaintext-only" suppressContentEditableWarning onBlur={doSave.bind(null, e as keyof typeof EXCEPTIONS, i, 'divide')} className="w-1 text-center">{typeof data.divide === 'object' ? data.divide.join(',') : data.divide || 1}</td>
                                            )}
                                            <td contentEditable="plaintext-only" suppressContentEditableWarning onBlur={doSave.bind(null, e as keyof typeof EXCEPTIONS, i, 'display')} className={e === 'inventory/confirm' || e === 'inventory/separate' ? '' : 'w-1'}>
                                                {Array.isArray(data.display) ? data.display.join(',') : (data.display || '')}
                                            </td>
                                            <td><button type="button" onClick={doDel.bind(null, e as keyof typeof EXCEPTIONS, i)}><span className="fa fa-trash" /></button></td>
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr>
                                    <td colSpan={e === 'inventory/skip' ? 4 : 5} className="text-center">
                                        <span className="far fa-calendar-xmark fa-3x" />
                                    </td>
                                </tr>
                            )}
                        </MDBTableBody>
                    </MDBTable>
                );
            })}
        </>
    );
}
