import { getConfig } from "../../api/core";
import { buildQuery, CancelablePromise } from "../client";
import CryptoJS from 'crypto-js';

type APIConfig = { gateway: string, api_key: string, api_secret: string };
const DEFAULT_GATEWAY = 'https://api.unleashedsoftware.com/';
let cached: APIConfig;

async function getAPIConfig() {
    if (!cached) {
        let storage = localStorage.getItem('unleashed');
        if (storage) {
            cached = JSON.parse(storage);
        }
    }
    if (cached) {
        return cached;
    } else {
        let response = await getConfig('erp/%');
        if (typeof response !== 'string' && response['erp/api_secret']) {
            let result: Partial<APIConfig> = {};
            for (let i in response) {
                result[i.replace('erp/', '') as keyof APIConfig] = response[i];
            }
            cached = result as APIConfig;
            localStorage.setItem('unleashed', JSON.stringify(cached));
            return cached;
        }
    }
    throw '';
}

export default function request(path: string, method = 'GET', data?: Record<string, any>) {
    let controller = new AbortController();
    return new CancelablePromise<any>((resolve, reject) => {
        getAPIConfig().then(async config => {
            let query = method === 'GET' ? buildQuery(data || { format: 'json' }) : 'format=json';
            let url = (config.gateway || DEFAULT_GATEWAY) + path + '?' + query;
            let request = new Request(url, {
                method, headers: [
                    ['Accept', 'application/json'],
                    ['Content-Type', 'application/json'],
                    ['api-auth-id', config.api_key],
                    ['api-auth-signature', sign(query, config.api_secret)],
                ],
                signal: controller.signal,
                ...(method === 'GET' || method === 'HEAD' ? {} : { body: data as string | FormData | Blob })
            });
            let response;
            let cache = await caches.open('unleashed');
            try {
                response = await cache.match(request, {});
                if (!response || parseFloat(localStorage.getItem(path + '?' + query + '_cache') || '0') < Date.now()) {
                    throw '';
                }
            } catch {
                response = await fetch(request);
                cache.put(request, response.clone());
                localStorage.setItem(path + '?' + query + '_cache', (Date.now() + 3600000).toString());
            }
            response.json().then(resolve);
        });
    }, () => controller.abort());
}

function sign(query: string, secret: string) {
    return CryptoJS.HmacSHA256(query, secret).toString(CryptoJS.enc.Base64);
}
