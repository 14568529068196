import React from "react";
import { type Columns } from "../../component/Edit";
import useLocale from "../../util/i18n";
import { useCrumbs } from "../../component/breadcrumbs";
import Config from "./Config";
import { Helmet } from "react-helmet-async";

export default function ConfigStock() {
    let [__] = useLocale();
    let [setCrumbs] = useCrumbs();
    let columns = React.useMemo<Columns>(() => ({
        [__('Stock')]: [
            {
                name: 'stock/box',
                label: __('Stock Grid'),
                required: true,
                comment: __('Comma Separated.')
            }
        ]
    }), [__]);
    React.useEffect(() => {
        setCrumbs(['Stock Config']);
    }, []);
    return (
        <>
            <Helmet>
                <title>{__('Stock Grid')}</title>
            </Helmet>
            <Config columns={columns} />
        </>
    );
}