import { MDBBtn, MDBCard, MDBCardBody, MDBInputGroup } from "mdb-react-ui-kit";
import React from "react";
import Select, { OptionType } from "../component/select";
import InputBox from "../component/input-box";
import useLocale, { I18N } from "../util/i18n";
// import ImageBox from "../component/image-box";
// import Uploader from "../component/uploader";
// import Locale from "../component/locale";
import Resource, { Picker, ResourceEventDetail } from "../component/resource";
import { Editor } from "@tinymce/tinymce-react";
// import env from "../env.json";
// import Category from "../component/category";
// import AutoComplete from "../component/autocomplete";
import { LightBoxRef } from "./lightbox";
import env from "../env.json";
import { useSearchParams } from "react-router-dom";
import Accordion, { AccordionItem } from "./accordion";
// import PriceBox from "./price";
import { CancelablePromise } from "../util/client";
import LocaleInput from "./locale-input";
import LangSelect from "./lang-select";

type Column = {
    name: string;
    label?: string;
    type?: React.HTMLInputTypeAttribute | 'component' | 'select' | 'textarea' | 'wysiwyg' | 'images' | 'image' | 'category' | 'locale';
    comment?: string;
    required?: boolean;
    condition?: (data?: Record<string,any>) => boolean;
    default?: string;
    disabled?: boolean;
    readonly?: boolean;
    component?: React.FunctionComponent<any> | React.ComponentClass;
    load?: (data?: Record<string, any>) => Promise<Array<OptionType>>;
    loadWithData?: boolean;
    options?: Array<OptionType>;
    emptyOption?: string;
    source?: string;
    link?: string;
    target?: React.HTMLAttributeAnchorTarget;
    onChange?: (value: string | Array<string>) => void;
} & Partial<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>>
    & Partial<React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>>;

export type Columns = { [title: string]: Array<Column> };

type BoxProps = Column & {
    data?: Record<string, any>;
    value?: string;
    lightbox?: LightBoxRef;
    area?: number;
};

export function Box(props: BoxProps) {
    const [value, setValue] = React.useState<string | Array<string>>('');
    let type = React.useMemo(() => props.type || 'text', [props.type]);
    let [__, lang] = useLocale();
    React.useEffect(() => {
        setValue(typeof props.value === 'undefined' ? '' : (props.value === null ? '' : props.value));
    }, [props.value]);
    let onChange = React.useCallback((e: React.ChangeEvent<any> | string | Array<string>) => {
        let result: any;
        if (typeof e === 'object' && 'target' in e) {
            e.stopPropagation();
            e.preventDefault();
            if (type === 'radio' && e.target.checked) {
                result = e.target.value;
            } else if (type === 'checkbox') {
                result = Array.isArray(value) ? value as Array<string> : [value];
                if (e.target.checked) {
                    result.push(e.target.value.toString());
                } else {
                    result.splice(result.indexOf(e.target.value.toString()), 1);
                }
            } else {
                result = e.target.value;
            }
        } else {
            result = e;
        }
        (props.onChange || setValue)(result);
    }, [type, value, props.onChange, setValue]);
    switch (type) {
        case 'label':
            return (
                <label>
                    <span className="label">{props.label}</span>
                    <InputBox label={props.label} comment={props.comment}>
                        <span>{value || (props.default || '')}&nbsp;</span>
                    </InputBox>
                </label>
            );
        case 'button':
            return (
                <label>
                    <span className="label">&nbsp;</span>
                    <InputBox comment={props.comment}>
                        <MDBBtn onClick={props.onClick as React.MouseEventHandler<HTMLElement>}>{props.label || ''}</MDBBtn>
                    </InputBox>
                </label>
            );
        case 'link':
            return (
                <label>
                    <span className="label">{props.label}</span>
                    <InputBox label={props.label} comment={props.comment}>
                        <a href={props.link || '#'} target={props.target} rel="noreferrer noopener">{value || (props.default || '')}&nbsp;</a>
                    </InputBox>
                </label>
            );
        case 'radio':
            return (
                <label>
                    <span className="label">{props.label}</span>
                    <InputBox label={props.label} required={props.required} comment={props.comment}>
                        <span>
                            {(props.options || []).map(option => (
                                <label className="form-check form-check-inline" key={props.name + '-' + option.value}>
                                    <input
                                        type={type} name={props.name} value={option.value} className="form-check-input"
                                        required={props.required} disabled={props.disabled}
                                        checked={value.toString() === option.value.toString()}
                                        onChange={onChange}
                                    />
                                    <span className="form-check-label">{option.label}</span>
                                </label>
                            ))}
                        </span>
                    </InputBox>
                </label>
            );
        case 'checkbox':
            let checked = Array.isArray(value) ? value as Array<string> : [value];
            return (
                <label>
                    <span className="label">{props.label}</span>
                    <InputBox label={props.label} required={props.required} comment={props.comment}>
                        <span>
                            {(props.options || []).map(option => (
                                <label className="form-check form-check-inline" key={props.name + '-' + option.value}>
                                    <input
                                        type={type} name={props.name + '[]'} value={option.value} className="form-check-input"
                                        required={props.required} disabled={props.disabled}
                                        checked={checked.indexOf(option.value.toString()) > -1}
                                        onChange={onChange}
                                    />
                                    <span className="form-check-label">{option.label}</span>
                                </label>
                            ))}
                        </span>
                    </InputBox>
                </label>
            );
        case 'select':
            return (
                <label>
                    <span className="label">{props.label}</span>
                    <Select name={props.name} value={value} label={props.label} multiple={props.multiple}
                        load={props.load} required={props.required} data={props.data} loadWithData={props.loadWithData}
                        onChange={onChange} comment={props.comment} disabled={props.disabled}
                        options={props.options || ((props.data || {})[props.source || ''] || undefined)}>
                        {props.required || props.multiple ? undefined : (<option value="">{props.emptyOption || ''}</option>)}
                    </Select>
                </label>
            );
        // case 'image':
        //     let inner = value || props.disabled ? (
        //         <span>
        //             <ImageBox
        //                 src={value || null}
        //                 {...props}
        //                 doDelete={() => setValue(null)}
        //             />
        //         </span>
        //     ) : (
        //         <span>
        //             {props.required ? (<input required hidden />) : null}
        //             <Uploader width={props.width} height={props.height} onUploaded={url => {
        //                 setValue(url);
        //             }} type={props.video ? 'video' : 'image'} />
        //         </span>
        //     );
        //     return props.label ? (
        //         <InputBox label={props.label} required={props.required} comment={props.comment}>
        //             {inner}
        //         </InputBox>
        //     ) : inner;
        case 'images':
            let values = Array.isArray(value) ? value : (value ? [value] : []);
            return (
                <label>
                    <span className="label">{props.label}</span>
                    <InputBox label={props.label} required={props.required} comment={props.comment} className="gap-2 px-2 py-3">
                        <>
                            <input type="hidden" className="form-control active" />
                            {
                                values.map((src, index) => (
                                    <Picker
                                        key={index + src}
                                        value={src}
                                        name={props.name + '[]'}
                                        doDelete={() => {
                                            values.splice(index, 1);
                                            setValue([...values]);
                                        }}
                                    />
                                ))
                            }
                            {props.required && values.length === 0 ? (<input required hidden />) : null}
                            {
                                !props.disabled && values.length < (props.maxLength || 10) ? (
                                    <Picker name={props.name + '[]'} onChange={url => {
                                        setValue([...values, url]);
                                    }} />
                                ) : null
                            }
                        </>
                    </InputBox>
                </label>
            );
        // case 'locale':
        //     return (
        //         <Locale
        //             label={props.label}
        //             required={props.required}
        //             country={props.data[props.countryName]}
        //             region={props.data[props.regionName]}
        //             city={props.data[props.cityName]}
        //             value={value}
        //             {...props}
        //         />
        //     );
        // case 'category':
        //     return (
        //         <Category
        //             label={props.label}
        //             required={props.required}
        //             level1={(props.data[props.path] || [])[0] || ''}
        //             level2={(props.data[props.path] || [])[1] || ''}
        //             level3={(props.data[props.path] || [])[2] || ''}
        //             value={value}
        //             {...props}
        //         />
        //     );
        case 'wysiwyg':
            return (
                <div className="px-0" style={{ height: 500 }}>
                    <Editor
                        tinymceScriptSrc={env.RES_URL + 'tinymce/tinymce.min.js'}
                        initialValue={props.value}
                        textareaName={props.name}
                        init={{
                            height: 500,
                            language: lang,
                            menubar: false,
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                            ],
                            toolbar: 'undo redo removeformat | blocks | ' +
                                'bold italic forecolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist image media table | ' +
                                'code | help',
                            resize: false,
                            file_picker_callback: (callback, value, meta) => {
                                document.dispatchEvent(new CustomEvent<ResourceEventDetail>('resource', {
                                    detail: {
                                        mime: meta.filetype || 'image',
                                        callback
                                    }
                                }))
                            }
                        }}
                    />
                </div>
            );
        case 'textarea':
            let areaAttr: React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> = {};
            for (let k of ['name', 'readOnly', 'disabled', 'required',
                'rows', 'cols', 'wrap', 'dirName',
                'maxLength', 'minLength', 'pattern'
            ]) {
                if (typeof props[k as keyof React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>] !== 'undefined') {
                    areaAttr[k as keyof React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>] = props[k as keyof React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>];
                }
            }
            return (
                <label>
                    <span className="label">{props.label}</span>
                    <InputBox label={props.label} required={props.required} comment={props.comment}>
                        <textarea {...areaAttr} className="form-control active" value={value} onChange={e => setValue(e.target.value)}></textarea>
                    </InputBox>
                </label>
            );
        case 'component':
            return props.component ? React.createElement<any>(props.component, { ...props, value }) : null;
        // case 'autocomplete':
        //     return (<AutoComplete {...props} value={value} />);
        // case 'price':
        //     return (
        //         <label>
        //             <span className="label">{props.label}</span>
        //             <PriceBox {...props} />
        //         </label>
        //     );
        case 'language':
            return (
                <label>
                    <span className="label">{props.label}</span>
                    <LangSelect {...props} />
                </label>
            );
        case 'locale':
            return props.area === 2 ? (<LocaleInput {...props} />) : (
                <label>
                    <span className="label">{props.label}</span>
                    <LocaleInput {...props} />
                </label>
            );
        default:
            let tmp: Record<string, any> = {};
            for (let k of ['name', 'readOnly', 'disabled', 'required', 'autoComplete',
                ...(['number', 'range', 'date', 'month', 'week', 'time', 'datetime-local'].indexOf(type) > -1 ? ['max', 'min', 'step'] : []),
                ...(type === 'file' ? ['accept', 'multiple'] : []),
                'maxLength', 'minLength', 'pattern'
            ]) {
                if (typeof props[k as keyof React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>] !== 'undefined') {
                    tmp[k] = props[k as keyof React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>];
                }
            }
            return (
                <label>
                    <span className="label">{props.label}</span>
                    <InputBox label={props.label} required={props.required} comment={props.comment}>
                        <input {...tmp} className="form-control active" type={type} value={value} onChange={onChange} />
                    </InputBox>
                </label>
            );
    }
}

type EditProps = {
    columns: Columns;
    id?: string;
    legend?: string;
    saveLabel?: string;
    doLoad?: (params: Record<string, any>) => CancelablePromise<any>;
    doSave?: (data: FormData) => void;
    doDelete?: (data: Record<string, any>) => void;
    btns?: JSX.Element | ((data: Record<string, any>) => JSX.Element);
    lightbox?: LightBoxRef;
};

function getValue(data: Record<string, any>, key: string): string | undefined {
    if (key.includes('[')) {
        if (key.endsWith(']')) {
            key = key.substring(0, key.length - 1);
        }
        let pos = key.indexOf('['), skip = 1;
        if (key[pos - 1] === ']') {
            pos--;
            skip = 2;
        }
        let value = data[key.substring(0, pos)];
        return value ? getValue(value, key.substring(pos + skip)) : undefined;
    } else {
        return data[key];
    }
}

function handleFormValue(name: string, value: any, pre: any): Record<string, any> {
    let p = name.indexOf('[');
    if (p < 0) {
        let k = name.substring(name[0] === ']' ? 1 : 0, name.length - (name[name.length - 1] === ']' ? 1 : 0));
        return typeof pre !== 'object' ? { [k]: value } : 
            (Array.isArray(pre) ? { [k]: [...(pre || []), value] } : { ...(pre || {}), [k]: value });
    } else {
        let k = name.substring(name[0] === ']' ? 1 : 0, p - (name[p - 1] === ']' ? 1 : 0));
        if (name.substring(p + 1) === ']') {
            return { [k]: [...((pre || {})[k] || []), value] };
        }
        let v = handleFormValue(name.substring(p + 1), value, pre?.[k]);
        return { [k]: v };
    }
}

export default function Edit(props: EditProps) {
    const [data, setData] = React.useState<Record<string, any>>({});
    const [resourceParams, setResourceParams] = React.useState<URLSearchParams>(new URLSearchParams);
    const debounce = React.useRef<CancelablePromise<any> | null>(null);
    const code = React.useRef('');
    const load = React.useRef((params: URLSearchParams | Record<string, any>) => {
        if (!props.doLoad) {
            return;
        }
        if (typeof params.entries !== 'undefined') {
            let tmp: Record<string, any> = {};
            for (const [k, v] of params.entries()) {
                tmp[k] = v;
            }
            params = tmp;
        }
        if (props.id && !(params as Record<string, any>)[props.id]) {
            return;
        }
        let c = JSON.stringify(params);
        if (debounce.current) {
            if (code.current === c) {
                return;
            }
            debounce.current.cancel();
        }
        code.current = c;
        (debounce.current = props.doLoad(params)).then(response => {
            setData(response);
            debounce.current = null;
        }, () => {
            debounce.current = null;
        });
    });
    const save = React.useCallback<React.FormEventHandler<HTMLFormElement>>((e) => {
        e.preventDefault();
        e.stopPropagation();
        let form = e.target as HTMLFormElement, flag = form.checkValidity();
        form.classList.add('was-validated');
        if (!flag) {
            for (const [, c] of form.querySelectorAll<HTMLDivElement>('.collapse').entries()) {
                let i = c.querySelector<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>(':invalid');
                let tab = c.parentElement?.querySelector('.accordion-header');
                if (i) {
                    tab?.classList.add('invalid');
                    if (!tab?.querySelector('.collapsed')) {
                        i.reportValidity();
                    }
                } else {
                    tab?.classList.remove('invalid');
                }
            }
        } else if (props.doSave) {
            props.doSave(new FormData(form));
        }
    }, [props]);
    let onChange = React.useCallback((name: string, value: any) => {
        setData(Object.assign({}, data, handleFormValue(name, value, data)));
    }, [data]);
    let [search] = useSearchParams();
    React.useEffect(() => {
        load.current(search);
    }, [search]);
    const form = React.useRef<HTMLFormElement>(null);
    let titles = React.useMemo(() => {
        let result = [];
        for (let title in props.columns) {
            result.push(title);
        }
        return result;
    }, [props.columns]);
    return (
        <>
            <form onSubmit={save} noValidate ref={form} className="edit">
                {props.legend ? (<h3 className="legend">{props.legend}</h3>) : null}
                <Accordion>
                    {
                        titles.map((title, index) => (
                            // @ts-ignore
                            <AccordionItem key={title} title={title}>
                                {props.columns[title].map(column => {
                                    if (column.condition?.(data) === false) {
                                        return (<React.Fragment  key={index + column.name}></React.Fragment>);
                                    }
                                    let value = getValue(data, column.name) ?? column.default;
                                    if (column.type === 'hidden') {
                                        return (<input key={index + column.name} type="hidden" name={column.name} value={value} />);
                                    }
                                    return (
                                        <Box
                                            key={index + column.name}
                                            {...column}
                                            lightbox={props.lightbox}
                                            value={value}
                                            data={data}
                                            onChange={onChange.bind(null, column.name)}
                                        />
                                    );
                                })}
                            </AccordionItem>
                        ))
                    }
                </Accordion>
                <MDBCard className="tools">
                    <MDBCardBody>
                        <div className="btns">
                            {typeof props.btns === 'function' ? props.btns(data) : props.btns}
                            {props.doDelete && data[props.id || 'id'] ? (
                                <MDBBtn type="button" color="danger" onClick={props.doDelete.bind(null, data)}><I18N>Delete</I18N></MDBBtn>
                            ) : null}
                            {props.doSave ? (
                                <>
                                    <MDBBtn type="reset" color="info"><I18N>Reset</I18N></MDBBtn>
                                    <MDBBtn type="submit" color="primary"><I18N>{props.saveLabel || 'Save'}</I18N></MDBBtn>
                                </>
                            ) : null}
                        </div>
                        <div className="comment"></div>
                    </MDBCardBody>
                </MDBCard>
            </form>
            <Resource query={resourceParams} setQuery={setResourceParams} />
        </>
    );
}
