import React from "react";
import useLocale from "../util/i18n";
import request from "../util/erp/unleashed";
import Select, { type OptionType } from "../component/select";
import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBInput, MDBRow, MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import { useCrumbs } from "../component/breadcrumbs";
import { Helmet } from "react-helmet-async";
import { getPurchaseOrderItem } from "../api/erp";
import BigNumber from "bignumber.js";

export default function Forecast() {
    let [params, setParams] = React.useState<Record<string, string> | null>(null);
    let [__, lang] = useLocale();
    const loadSupplier = React.useCallback(async () => {
        let options: Array<OptionType> = [];
        let response = await request('Suppliers');
        for (let i of (response.Items || [])) {
            options.push({
                value: i.Guid, label: i.SupplierName
            });
        }
        return options;
    }, [setParams]);
    const loadProductGroup = React.useCallback(async () => {
        let options: Array<OptionType> = [];
        let response = await request('ProductGroups');
        for (let i of (response.Items || [])) {
            options.push({
                value: i.GroupName, label: i.GroupName
            });
        }
        return options;
    }, [setParams]);
    let [setCrumbs] = useCrumbs();
    let formatter = React.useMemo(() => new Intl.DateTimeFormat(lang, { dateStyle: 'medium' }), [lang]);
    let getMonth = React.useCallback((i: number) => {
        let parts = formatter.formatToParts(Date.now() - i * 2592000000);
        let result = '';
        for (let i = 0; i < parts.length; i++) {
            if (parts[i].type === 'month') {
                result = parts[i].value + (parts[i + 1]?.value || '');
                break;
            }
        }
        return result.trim();
    }, [formatter]);
    let onSubmit = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
        e.stopPropagation();
        e.preventDefault();
        let data = new FormData(e.target as HTMLFormElement);
        let result: Record<string, string> = {};
        for (const [k, v] of data.entries()) {
            result[k] = v as string;
        }
        setParams(result);
    }, [setParams]);
    React.useEffect(() => {
        setCrumbs(['Forecast']);
        for(let i=1;i<=5;i++) {
            request('SalesShipments/Page/' + i, 'GET').then(response => {
                for(let o of response.Items) {
                    !o.Guid && console.log(o.Guid);
                }
            });
        }
    }, [setParams]);
    return (
        <div className="forecast">
            <Helmet>
                <title>{__('Forecast')}</title>
            </Helmet>
            <MDBCard>
                <MDBCardBody className="edit" tag="form" onSubmit={onSubmit}>
                    <MDBRow className="g-3">
                        <MDBCol size="12" lg="6" xxl="3" className="d-flex flex-column gap-3">
                            <h3>{__('Products')}</h3>
                            <Select load={loadSupplier} name="supplier" label={__('Supplier')} />
                            <Select load={loadProductGroup} name="product_group" label={__('Product Group')} />
                            <h3>{__('Average Unit Sales')}</h3>
                            <MDBInput type="number" name="ausb" label={__('Average Unit Sales Base on')} defaultValue={10} />
                        </MDBCol>
                        <MDBCol size="12" lg="6" xxl="3" className="d-flex flex-column gap-3">
                            <h3>{__('In Transit Time')}</h3>
                            <MDBInput type="number" name="scpt" label={__('Supplier Current Production Time')} defaultValue={10} />
                            <MDBInput type="number" name="coft" label={__('Current Ocean Freight Time')} defaultValue={10} />
                        </MDBCol>
                        <MDBCol size="12" lg="6" xxl="3" className="d-flex flex-column gap-3">
                            <h4>{__('Average of Past 1-6 Months Unit Sales')}</h4>
                            {[1, 2, 3, 4, 5, 6].map(i => (
                                <MDBInput key={'aus-' + i} name={'aus-' + i} type="number" label={__('Average Unit Sales in %s').replace('%s', getMonth(i))} defaultValue={10} />
                            ))}
                        </MDBCol>
                        <MDBCol size="12" lg="6" xxl="3" className="d-flex flex-column gap-3">
                            <h3>{__('Forecast Sales Increase')}</h3>
                            {[1, 2, 3, 4, 5, 6].map(i => (
                                <MDBInput key={'fsi-' + i} name={'fsi-' + i} type="number" label={__('Next %d Month Increment').replace('%d', i.toString())} defaultValue={10} />
                            ))}
                        </MDBCol>
                        <MDBCol size="12" className="text-center">
                            <MDBBtn type="submit" className="px-5">{__('Run')}</MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </MDBCardBody>
            </MDBCard>
            {params ? (<Table params={params} />) : null}
        </div>
    );
}

function Table(props: { params: Record<string, any> }) {
    let [skus, setSkus] = React.useState<Array<string>>([]);
    let [qtys, setQtys] = React.useState<Record<string, number>>({});
    let [op, setOp] = React.useState<Record<string, { qty: number, ordered_at: number, delivered_at: number | null }>>({});
    let itt = React.useCallback((item: { ordered_at: number, delivered_at: number | null }, fixed = true) => {
        let result = new BigNumber(props.params.coft).times(86400);
        if (item.delivered_at) {
            result = result.plus(item.delivered_at);
        } else {
            result = result.plus(item.ordered_at).plus(new BigNumber(props.params.scpt).times(86400));
        }
        result = result.minus(Date.now() / 1000).div(2592000);
        return fixed ? parseFloat(result.toFixed(1)) : result;
    }, [props.params]);
    let fus = React.useCallback((item: { ordered_at: number, delivered_at: number | null }, fixed = true) => {
        let it = itt(item, false) as BigNumber;
        let result = new BigNumber(0);
        let aus = new BigNumber(result);
        for (let i = 1; i <= 6 && it.integerValue(BigNumber.ROUND_DOWN).gte(i); i++) {
            if (parseFloat(props.params['fsi-' + i] || '0') > 0) {
                continue;
            }
            let v = aus;
            for (let j = 1; j <= i; j++) {
                v = v.times(new BigNumber(props.params['fsi-' + j] || 0).div(100).plus(1));
            }
            result = result.plus(v);
        }
        let v = aus;
        for (let j = 1; it.integerValue(BigNumber.ROUND_DOWN).gte(j); j++) {
            v = v.times(new BigNumber(props.params['fsi-' + j] || 0).div(100).plus(1));
        }
        result = result.plus(v.times(it.minus(it.integerValue(BigNumber.ROUND_DOWN))));
        return fixed ? parseFloat(result.toFixed(1)) : result;
    }, [props.params, itt]);
    React.useEffect(() => {
        (async () => {
            let skus: Array<string> = [], qtys: Record<string, number> = {};
            for (let i = 1; ; i++) {
                let response = await request('Products/Page/' + i, 'GET', { productGroup: props.params.product_group, pageSize: 50 });
                for (let item of (response.Items || [])) {
                    skus.push(item.ProductCode);
                }
                if (i >= (response.Pagination?.NumberOfPages || 0)) {
                    break;
                }
            }
            for (let i = 1; ; i++) {
                let response = await request('StockOnHand/Page/' + i);
                for (let item of (response.Items || [])) {
                    if (skus.includes(item.ProductCode)) {
                        qtys[item.ProductCode] = parseFloat(item.AvailableQty);
                    }
                }
                if (i >= (response.Pagination?.NumberOfPages || 0)) {
                    break;
                }
            }
            let opr = await getPurchaseOrderItem({ filter: { sku: { like: props.params.product_group + '%' }, 'order.status': { ne: 'Complete' } }, limit: -1 });
            if (typeof opr !== 'string') {
                let op: Record<string, { qty: number, ordered_at: number, delivered_at: number | null }> = {};
                for (let i of opr.data) {
                    op[i.sku] = {
                        qty: (op[i.sku]?.qty || 0) + i.ordered_qty,
                        ordered_at: Math.min((op[i.sku]?.ordered_at || Number.MAX_SAFE_INTEGER), i.ordered_at),
                        delivered_at: i.delivered_at ? Math.min((op[i.sku]?.delivered_at || Number.MAX_SAFE_INTEGER), i.delivered_at) : (op[i.sku]?.delivered_at || null)
                    };
                }
                setOp(op);
            }
            setSkus(skus);
            setQtys(qtys);
        })();
    }, [props.params, setSkus, setQtys, setOp]);
    return skus.length ? (
        <MDBCard className="mt-4">
            <MDBCardBody>
                <MDBTable bordered hover responsive small className="text-center">
                    <MDBTableHead>
                        <tr>
                            <th className="text-start">SKU</th><th>AQ</th><th>OP</th><th>OPT</th><th>AUS</th><th>ITT</th>
                            {[1, 2, 3, 4, 5, 6].map(i => props.params['fsi-' + i] ? (
                                <th key={'fsi-' + i} className="text-nowrap">FSi-{i}</th>
                            ) : null)}
                            <th>FUS</th><th>MQ</th><th>EPOQ</th><th>FOB/Unit Price</th><th>Line TT</th><th>CBM</th><th>CBM TT</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {skus.map((sku: any, i: number) => (
                            <tr key={sku}>
                                <td className="text-start">{sku}</td>
                                <td>{qtys[sku] || 0}</td>
                                <td>{op[sku]?.qty || 0}</td>
                                <td>{op[sku] ? itt(op[sku]) as number : -1}</td>
                                <td>{props.params.ausb || 10}</td>
                                <td>{parseFloat(((parseFloat(props.params.coft || 10) + parseFloat(props.params.scpt || 10)) / 30).toFixed(1))}</td>
                                {[1, 2, 3, 4, 5, 6].map(i => props.params['fsi-' + i] ? (
                                    <td key={'fsi-' + i}>{(props.params['fsi-' + i] || '0') + '%'}</td>
                                ) : null)}
                                <td>{op[sku] ? fus(op[sku]) as number : -1}</td>
                                <td>{parseFloat((new BigNumber(qtys[sku] || 0)).plus(op[sku] ? (new BigNumber(op[sku].qty)).minus(fus(op[sku], false)) : 0).toFixed(1))}</td>
                                <td>{parseFloat((new BigNumber(qtys[sku] || 0)).plus(op[sku] ? (new BigNumber(op[sku].qty)).minus(fus(op[sku], false)) : 0).toFixed(1))}</td>
                                <td></td><td></td><td></td><td></td>
                            </tr>
                        ))}
                    </MDBTableBody>
                </MDBTable>
            </MDBCardBody>
        </MDBCard>
    ) : (
        <div className="loading bg-white mt-4 text-center p-3"><span className="fa fa-spin fa-spinner fa-4x" /></div>
    );
}
