import request from "../util/client";
import { defaultListParams, defaultListResponse } from "./types";

interface PurchaseOrder {
    id: string;
    order_id: string;
    ordered_at: number;
    delivered_at: number;
    received_at: number;
    status: string;
}

interface PurchaseOrderItem {
    id: string;
    order_id: string;
    sku: string;
    ordered_qty: number;
    receipt_qty: number;
}

export function getPurchaseOrderItem(params: defaultListParams<any>) {
    return request<defaultListResponse<PurchaseOrderItem & { delivered_at: number | null, ordered_at: number }>>('purchase_order/item', 'GET', params);
}

export function getSoldQty(params: defaultListParams<any>) {
    return request<defaultListResponse<{ sku: string, qty: number }>>('sales_order/qty', 'GET', params);
}
