import React from 'react';
import { MDBBtn, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle } from 'mdb-react-ui-kit';
import { usePayload } from '../util/token';
import Breadcrumbs from './breadcrumbs';
import useLocale from '../util/i18n';
import { useLocation } from 'react-router-dom';
import env from '../env.json';

export default function Header() {
    let [payload, _1, logout] = usePayload();
    let [__, _, setLang] = useLocale();
    let location = useLocation();
    let [newUrl, setNewUrl] = React.useState<string>();
    let [searchable, setSearchable] = React.useState(false);
    React.useEffect(() => {
        window.setTimeout(() => {
            let grid = document.querySelector('[data-new-url]') as HTMLDivElement | null;
            if (grid) {
                setNewUrl(grid.dataset.newUrl);
            } else {
                setNewUrl(undefined);
            }
            setSearchable(Boolean(document.querySelector('[data-searchable]')));
        }, 600);
    }, [location]);
    const toggleNav = React.useRef(() => {
        let l = document.querySelector('.nav-container')?.classList;
        if (l?.contains('active')) {
            l?.remove('active');
        } else {
            l?.add('active');
        }
    });
    const onClick = React.useRef((event: string, e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.stopPropagation();
        e.preventDefault();
        document.dispatchEvent(new Event(event));
    });
    React.useEffect(() => {
        let l = (e: KeyboardEvent) => {
            if (searchable && e.key === 'f' && (e.metaKey || e.ctrlKey)) {
                e.stopPropagation();
                e.preventDefault();
                document.dispatchEvent(new Event('search'));
            } else if (newUrl && e.key === 'n' && (e.metaKey || e.ctrlKey)) {
                e.stopPropagation();
                e.preventDefault();
                document.dispatchEvent(new Event('new'));
            }
        }
        document.addEventListener('keydown', l);
        return () => document.removeEventListener('keydown', l);
    }, [newUrl, searchable]);
    return (
        <header className="header">
            <div className="tools d-block d-md-none">
                <MDBBtn onClick={toggleNav.current} color="link" title={__('Menu')}>
                    <span className="fa fa-fw fa-bars" />
                </MDBBtn>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-center justify-content-md-start">
                <img src={payload?.avatar || env.LOGO} className="logo d-none d-md-block" width="32" height="32" alt="Logo" />
                <Breadcrumbs />
            </div>
            <div className="tools">
                {searchable ? (
                    (<MDBBtn onClick={onClick.current.bind(null, 'search')} color="link" title={__('Search')}>
                        <span className="fa fa-fw fa-magnifying-glass" aria-hidden="true" />
                        <span className="sr-only">{__('Search')}</span>
                    </MDBBtn>)
                ) : null}
                {newUrl ? (
                    (<MDBBtn onClick={onClick.current.bind(null, 'new')} color="link" title={__('Create New Data')}>
                        <span className="fa fa-fw fa-square-plus" aria-hidden="true" />
                        <span className="sr-only">{__('Create New Data')}</span>
                    </MDBBtn>)
                ) : null}
                <MDBDropdown>
                    <MDBDropdownToggle color="link">
                        <span className="fa fa-fw fa-language" aria-hidden="true" />
                        <span className="sr-only">{__('Language')}</span>
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                        <MDBDropdownItem link onClick={setLang.bind(null, 'en')}>English</MDBDropdownItem>
                        <MDBDropdownItem link onClick={setLang.bind(null, 'zh-CN')}>简体中文</MDBDropdownItem>
                    </MDBDropdownMenu>
                </MDBDropdown>
                <MDBBtn onClick={logout} color="link" title={__('Logout')}>
                    <span className="fa fa-fw fa-right-from-bracket" aria-hidden="true" />
                    <span className="sr-only">{__('Logout')}</span>
                </MDBBtn>
            </div>
        </header>
    );
}
