import React from "react";
import { type Columns } from "../../component/Edit";
import useLocale from "../../util/i18n";
import { useCrumbs } from "../../component/breadcrumbs";
import Config from "./Config";
import { Helmet } from "react-helmet-async";

export default function ConfigEmail() {
    let [__] = useLocale();
    let [setCrumbs] = useCrumbs();
    let columns = React.useMemo<Columns>(() => ({
        [__('Email')]: [
            {
                name: 'email/host',
                label: __('Host'),
                type: 'url',
                required: true
            },
            {
                name: 'email/port',
                label: __('Port'),
                type: 'number',
                min: 0,
                max: 65535,
                required: true
            },
            {
                name: 'email/tsl',
                label: 'TLS',
                type: 'select',
                default: '1',
                required: true,
                options: [
                    { value: '1', label: __('Enabled')},
                    { value: '0', label: __('Disabled')}
                ]
            },
            {
                name: 'email/username',
                label: __('Username')
            },
            {
                name: 'email/password',
                label: __('Password')
            },
            {
                name: 'email/sender',
                label: __('Sender Email'),
                type: 'email',
                required: true
            },
            {
                name: 'email/sender_name',
                label: __('Sender Name')
            }
        ]
    }), [__]);
    React.useEffect(() => {
        setCrumbs(['Email Config']);
    }, []);
    return (
        <>
            <Helmet>
                <title>{__('Email Config')}</title>
            </Helmet>
            <Config columns={columns} />
        </>
    );
}