import React from "react";
import { Helmet } from "react-helmet-async";
import Config from "./Config";
import useLocale from "../../util/i18n";
import { useCrumbs } from "../../component/breadcrumbs";
import { type Columns } from "../../component/Edit";
import { oAuthRequest } from "../../api/oauth";
import Toast from "../../component/toast";

export default function ConfigAPI() {
    let [__] = useLocale();
    let columns = React.useMemo<Columns>(() => ({
        ERP: [
            {
                name: 'erp/service',
                label: __('Service'),
                type: 'select',
                options: [
                    { value: 'unleashed', label: 'Unleashed' },
                    { value: 'zoho', label: 'Zoho' }
                ]
            },
            {
                name: 'erp/gateway',
                label: __('Gateway'),
                type: 'url'
            },
            {
                name: 'erp/api_key',
                label: 'API Key'
            },
            {
                name: 'erp/api_secret',
                label: 'API Secret'
            },
            {
                name: 'erp/redirect',
                label: __('Redirect URL'),
                type: 'url',
                comment: __('This should be same with the configuration on the third party platform.'),
                condition: data => data?.['erp/service'] === 'zoho'
            },
            {
                name: 'erp/refresh',
                label: __('Token'),
                readOnly: true,
                condition: data => data?.['erp/service'] === 'zoho'
            },
            {
                name: '',
                label: __('Request'),
                type: 'button',
                onClick: e => {
                    e.preventDefault();
                    e.stopPropagation();
                    oAuthRequest('zoho').then(response => {
                        window.open(response, '_blank');
                    }, () => {
                        Toast.show(__('An error detected.'));
                    });
                },
                condition: data => data?.['erp/service'] === 'zoho' && !data?.['erp/refresh']
            }
        ],
        [__('Inventory')]: [
            {
                name: 'inventory/auth',
                label: __('Temporary Authorization'),
            }
        ]
    }), [__]);
    let [setCrumbs] = useCrumbs();
    React.useEffect(() => {
        setCrumbs(['API Config']);
    }, []);
    return (
        <>
            <Helmet>
                <title>{__('API Config')}</title>
            </Helmet>
            <Config columns={columns} />
        </>
    );
}
