import React from "react";
import Edit, { type Columns } from "../../component/Edit";
import useLocale from "../../util/i18n";
import { useCrumbs } from "../../component/breadcrumbs";
import { getConfig, saveConfig } from "../../api/core";
import Toast from "../../component/toast";

interface ConfigProps {
    columns: Columns;
    paths?: string;
}

export default function Config(props: ConfigProps) {
    let [__, lang] = useLocale();
    let [setCrumbs] = useCrumbs();
    let paths = React.useMemo<string | Array<string>>(() => {
        if (props.paths) {
            return props.paths;
        }
        let result: Array<string> = [];
        for (let title in props.columns) {
            for (let column of props.columns[title]) {
                result.push(column.name);
            }
        }
        return result;
    }, [props]);
    const doLoad = React.useCallback(() => {
        return getConfig(paths);
    }, [lang, paths]);
    const doSave = React.useCallback((data: FormData) => {
        let params = [];
        for (const [k, v] of data.entries()) {
            params.push({
                path: k,
                value: v as string
            });
        }
        saveConfig(params).then(response => Toast.show(typeof response === 'string' ? response : response.message));
    }, [lang]);
    React.useEffect(() => {
        setCrumbs(['General Config']);
    }, []);
    return (
        <>
            <Edit columns={props.columns} doLoad={doLoad} doSave={doSave} />
        </>
    );
}