import React from "react";
import Grid, { Columns } from "../../component/Grid";
import useLocale from "../../util/i18n";
import { Helmet } from "react-helmet-async";
import { useCrumbs } from "../../component/breadcrumbs";
import { getStockHistory, GridConfig } from "../../api/stock";
import { useSearchParams } from "react-router-dom";
import { parseSku } from "../../util/misc";
import { getConfig } from "../../api/core";

export default function History() {
    let [__, lang] = useLocale();
    let [setCrumbs] = useCrumbs();
    let [search, setSearch] = useSearchParams();
    let [config, setConfig] = React.useState<GridConfig[]>([]);
    let formatter = React.useMemo(() => new Intl.DateTimeFormat(lang, { dateStyle: 'short', timeStyle: 'short' }), [lang]);
    let columns = React.useMemo<Columns>(() => ({
        sku: {
            label: 'SKU',
            format: value => parseSku(value) || (<span className="fa fa-xmark fa-2x text-danger" />)
        },
        position: {
            label: __('Position'),
            format: (_, item) => (config[item.area]?.name || 'Area ' + (item.area + 1)) + ': ' + (item.row + 1) + 'x' + (item.col + 1)
        },
        qty: {
            label: __('Qty'),
            className: 'w-1 text-center text-nowrap',
            format: value => parseFloat(value.toString()).toString()
        },
        created_at: {
            label: __('Stock In'),
            className: 'w-1 text-center text-nowrap',
            format: value => formatter.format(value * 1000)
        },
        deleted_at: {
            label: __('Stock Out'),
            className: 'w-1 text-center text-nowrap',
            format: value => value && formatter.format(value * 1000)
        }
    }), [__, formatter, config]);
    const doLoad = React.useRef((params: Record<string, any>) => {
        return getStockHistory(params);
    });
    React.useEffect(() => {
        setCrumbs(['Grid History']);
        getConfig('stock/box').then(response => typeof response !== 'string' && setConfig(JSON.parse(response['stock/box'])));
    }, []);
    return (
        <>
            <Helmet>
                <title>{__('Grid History')}</title>
            </Helmet>
            <Grid columns={columns} doLoad={doLoad.current} query={search} setQuery={setSearch} />
        </>
    );
}