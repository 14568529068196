import React from "react";
import { getStock, getStockHistory, GridConfig, StockData, StockHistoryData, stockIn, stockOut } from "../../api/stock";
import { MDBBadge, MDBBtn, MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import useLocale from "../../util/i18n";
import { useCrumbs } from "../../component/breadcrumbs";
import Toast from "../../component/toast";
import { ModalRef, parseSku, parseCode } from "../../util/misc";
import { saveConfig } from "../../api/core";
import Pager from "../../component/pager";
import { Link } from "react-router-dom";

export default function Stock() {
    let [data, setData] = React.useState<{ [key: string]: StockData }>({});
    let [config, setConfig] = React.useState<Array<GridConfig>>([]);
    let [setCrumbs] = useCrumbs();
    let [__] = useLocale();
    let doStockOut = React.useCallback((position: Array<[number, number, number]>) => {
        let cloned = { ...data }
        for (const p of position) {
            delete cloned[p.join('-')];
        }
        setData(cloned);
        stockOut({ position }).catch(() => {
            setData(data);
            Toast.show(__('An error detected. Please try again later.'), 'danger');
        });
    }, [data, setData, __]);
    let doStockIn = React.useCallback((area: number, row: number, col: number) => {
        let sku = window.prompt(__('Please enter the SKU to stock in.'));
        if (sku) {
            let cloned = { ...data }
            cloned[area + '-' + row + '-' + col] = { area, row, col, sku, qty: 1 };
            setData(cloned);
            stockIn({ area, row, col, sku: { [parseCode(sku)]: 1 } }).catch(() => {
                setData(data);
                Toast.show(__('An error detected. Please try again later.'), 'danger');
            });
        }
    }, [data, setData, __]);
    const historyRef = React.useRef<ModalRef<[number, number, number]>>(null);
    const configRef = React.useRef<ConfigRef>(null);
    let doSaveConfig = React.useCallback((data: GridConfig, index: number) => new Promise<void>(resolve => {
        let cloned = [...config], bak = [...config];
        if (index < 0) {
            cloned.push(data);
        } else {
            cloned[index] = { ...(cloned[index] || {}), ...data };
        }
        setConfig(cloned);
        saveConfig({ path: 'stock/box', value: JSON.stringify(cloned) }).then(() => resolve(), () => {
            Toast.show(__('An error detected. Please try again later.'), 'danger');
            setConfig(bak);
        });
    }), [config, setConfig, __]);
    let doDeleteConfig = React.useCallback((index: number) => new Promise<void>(resolve => {
        if (index < 0) {
            resolve();
            return;
        }
        let cloned = [...config], bak = [...config];
        cloned.splice(index, 1);
        setConfig(cloned);
        saveConfig({ path: 'stock/box', value: JSON.stringify(cloned) }).then(() => resolve(), () => {
            Toast.show(__('An error detected. Please try again later.'), 'danger');
            setConfig(bak);
        });
    }), [config, setConfig, __]);
    let doEnable = React.useCallback((area: number, row: number, col: number) => {
        let cloned = [...config], bak = [...config];
        cloned[area].disabled ??= [];
        let target = cloned[area].disabled.findIndex(i => i[0] == row && i[1] == col);
        cloned[area].disabled.splice(target, 1);
        setConfig(cloned);
        saveConfig({ path: 'stock/box', value: JSON.stringify(cloned) }).catch(() => {
            Toast.show(__('An error detected. Please try again later.'), 'danger');
            setConfig(bak);
        });
    }, [config, setConfig, __]);
    let doDisable = React.useCallback((area: number, row: number, col: number) => {
        let cloned = [...config], bak = [...config];
        cloned[area].disabled ??= [];
        cloned[area].disabled.push([row, col]);
        setConfig(cloned);
        saveConfig({ path: 'stock/box', value: JSON.stringify(cloned) }).catch(() => {
            Toast.show(__('An error detected. Please try again later.'), 'danger');
            setConfig(bak);
        });
    }, [config, setConfig, __]);
    React.useEffect(() => {
        getStock().then(response => {
            if (typeof response !== 'string') {
                let data: { [key: string]: StockData } = {};
                for (let i of (response.data || [])) {
                    data[i.area + '-' + i.row + '-' + i.col] = i;
                }
                setData(data);
                setConfig(response.config);
            }
        });
        setCrumbs(['Stock Grid']);
    }, []);
    return (
        <div className="stock-grid">
            {config.map((config, idx) => (
                <MDBTable key={'table-' + idx} className="bg-white text-center mb-0" bordered striped small responsive>
                    <colgroup>
                        <col width="1" />
                        {(new Array(config.cols)).fill(1).map((_, j) => (<col key={idx + 'c' + j} />))}
                    </colgroup>
                    <MDBTableHead>
                        <tr>
                            <th>
                                {config.name || __('Area %d').replace('%d', (idx + 1).toString())}
                                <button type="button" title={__('Edit')} onClick={configRef.current?.bind(null, config, idx)}>
                                    <span className="fa fa-edit" />
                                </button>
                                <Link to={'/stock/history?area=' + idx} className="history" title={__('History')}>
                                    <span className="fa fa-clock-rotate-left" />
                                </Link>
                            </th>
                            {(new Array(config.cols)).fill(1).map((_, j) => (
                                <th key={idx + 'h' + j} style={{ width: 100 / config.cols + '%' }}>
                                    {j + 1}
                                    <Link to={'/stock/history?area=' + idx + '&col=' + j} className="history" title={__('History')}>
                                        <span className="fa fa-clock-rotate-left" />
                                    </Link>
                                </th>
                            ))}
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {(new Array(config.rows)).fill(1).map((_, i) => (
                            <tr key={idx + '-' + i}>
                                <th>
                                    {i + 1}
                                    <Link to={'/stock/history?area=' + idx + '&row=' + i} className="history" title={__('History')}>
                                        <span className="fa fa-clock-rotate-left" />
                                    </Link>
                                </th>
                                {(new Array(config.cols)).fill(1).map((_, j) => {
                                    let key = idx + '-' + i + '-' + j;
                                    return (
                                        <td key={key}>
                                            {(config.disabled || []).find(t => t[0] === i && t[1] === j) ? (
                                                <button type="button" title={__('Disabled')} className="banned" onClick={doEnable.bind(null, idx, i, j)}>
                                                    <span className="fa fa-ban" />
                                                </button>
                                            ) : (data[key] ? (
                                                <>
                                                    <span>{parseSku(data[key].sku) || (<span className="fa fa-xmark fa-2x text-danger" />)}</span>
                                                    <MDBBadge>{parseFloat(data[key].qty.toString())}</MDBBadge>
                                                    <button type="button" title={__('Stock Out')} onClick={doStockOut.bind(null, [[idx, i, j]])}>
                                                        <span className="fa fa-dolly" />
                                                    </button>
                                                    <button type="button" className="history" title={__('History')} onClick={() => historyRef.current?.show([idx, i, j])}>
                                                        <span className="fa fa-clock-rotate-left" />
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    <span>&nbsp;</span>
                                                    <button type="button" title={__('Stock In')} onClick={doStockIn.bind(null, idx, i, j)}>
                                                        <span className="fa fa-plus" />
                                                    </button>
                                                    <button type="button" title={__('Disable')} onClick={doDisable.bind(null, idx, i, j)}>
                                                        <span className="fa fa-ban" />
                                                    </button>
                                                    <button type="button" className="history" title={__('History')} onClick={() => historyRef.current?.show([idx, i, j])}>
                                                        <span className="fa fa-clock-rotate-left" />
                                                    </button>
                                                </>
                                            ))}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </MDBTableBody>
                </MDBTable>
            ))}
            <History config={config} ref={historyRef} />
            <Config doSave={doSaveConfig} doDelete={doDeleteConfig} ref={configRef} />
        </div>
    );
}

type ConfigRef = (config: GridConfig, i: number) => void;
interface ConfigProps {
    doSave: (config: GridConfig, index: number) => Promise<void>;
    doDelete: (index: number) => Promise<void>;
}

const Config = React.forwardRef<ConfigRef, ConfigProps>((props, ref) => {
    let [data, setData] = React.useState<GridConfig | null>(null);
    let [index, setIndex] = React.useState(-1);
    let [loading, setLoading] = React.useState(false);
    let [__] = useLocale();
    let onChange = React.useCallback((key: 'name' | 'rows' | 'cols', e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        if (data) {
            let value = e.target.value || '';
            if (key !== 'name') {
                let n = parseFloat(value);
                setData({ ...data, [key]: Number.isNaN(n) ? value : n });
            } else {
                setData({ ...data, [key]: value });
            }
        }
    }, [data, setData]);
    let onSubmit = React.useCallback((e: React.FormEvent) => {
        e.stopPropagation();
        e.preventDefault();
        let data = new FormData(e.target as HTMLFormElement);
        let params = {
            name: data.get('name') as string,
            rows: parseInt((data.get('rows') || '10') as string),
            cols: parseInt((data.get('cols') || '10') as string)
        };
        setLoading(true);
        props.doSave(params, index).then(() => {
            setLoading(false);
            setData(null);
        });
    }, [setData, props.doSave, setLoading, index]);
    let onDelete = React.useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        setLoading(true);
        props.doDelete(index).then(() => {
            setLoading(false);
            setData(null);
        });
    }, [setData, props.doSave, setLoading, index]);
    React.useImperativeHandle(ref, () => (
        (config, i) => {
            setData(config);
            setIndex(i);
        }
    ), [setData, setIndex]);
    React.useEffect(() => {
        let l = () => {
            setData({ rows: 10, cols: 10 });
            setIndex(-1);
        };
        window.setTimeout(() => {
            document.addEventListener('new', l);
        }, 300);
        return () => {
            document.removeEventListener('new', l);
        };
    }, [setData, setIndex]);
    return (
        <>
            <div hidden data-new-url="#" />
            <MDBModal open={Boolean(data)} onClose={setData.bind(null, null)} tabIndex='-1'>
                <MDBModalDialog centered size="sm">
                    <MDBModalContent tag="form" onSubmit={onSubmit}>
                        <MDBModalBody>
                            <MDBInput name="name" value={data?.name} label={__('Name')} className="active" onChange={onChange.bind(null, 'name')} />
                            <MDBInput type="number" name="rows" value={data?.rows} min={1} step={1} label={__('Rows')} className="active my-3" required onChange={onChange.bind(null, 'rows')} />
                            <MDBInput type="number" name="cols" value={data?.cols} min={1} step={1} label={__('Columns')} className="active" required onChange={onChange.bind(null, 'cols')} />
                        </MDBModalBody>
                        <MDBModalFooter>
                            {index < 0 ? null : (<MDBBtn type="button" color="danger" onClick={onDelete} size="sm">{__('Delete')}</MDBBtn>)}
                            <MDBBtn type="button" color="secondary" onClick={setData.bind(null, null)} size="sm">{__('Close')}</MDBBtn>
                            <MDBBtn type="submit" disabled={loading} size="sm">{__('Save')}</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
});

const History = React.forwardRef<ModalRef<[number, number, number]>, { config: Array<GridConfig> }>(function (props, ref) {
    let [position, setPosition] = React.useState<[number, number, number] | null>(null);
    let [data, setData] = React.useState<Array<StockHistoryData>>([]);
    let [total, setTotal] = React.useState(0);
    let [search, setSearch] = React.useState(new URLSearchParams([['page', '1'], ['limit', '10']]));
    let [__, lang] = useLocale();
    let formatter = React.useMemo(() => new Intl.DateTimeFormat(lang, { dateStyle: 'short', timeStyle: 'short' }), [lang]);
    React.useImperativeHandle(ref, () => ({
        show: setPosition,
        hide: () => {
            setPosition(null);
            setData([]);
        }
    }), [setPosition, setData]);
    React.useEffect(() => {
        if (position) {
            getStockHistory(position, search).then(response => {
                if (typeof response !== 'string') {
                    setData(response.data);
                    setTotal(response.total);
                }
            });
        }
    }, [position, search]);
    return (
        <MDBModal open={Boolean(position)} onClose={setPosition.bind(null, null)} tabIndex='-1'>
            <MDBModalDialog centered size="fullscreen">
                <MDBModalContent>
                    <MDBModalBody>
                        {data.length ? (
                            <MDBTable striped hover>
                                <colgroup>
                                    <col />
                                    <col width="1" />
                                    <col width="1" />
                                    <col width="1" />
                                </colgroup>
                                <MDBTableHead>
                                    <tr>
                                        <th>SKU</th>
                                        <th className="text-center">{__('Qty')}</th>
                                        <th className="text-center">{__('Stock In')}</th>
                                        <th className="text-center">{__('Stock Out')}</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {data.map((item, index) => (
                                        <tr key={'history-' + index}>
                                            <th>{parseSku(item.sku) || (<span className="fa fa-xmark fa-2x text-danger" />)}</th>
                                            <td className="text-center">{parseFloat(item.qty.toString())}</td>
                                            <td className="text-nowrap">{formatter.format(item.created_at * 1000)}</td>
                                            <td className="text-nowrap">{item.deleted_at ? formatter.format(item.deleted_at * 1000) : ''}</td>
                                        </tr>
                                    ))}
                                </MDBTableBody>
                            </MDBTable>
                        ) : (
                            <span className="far fa-calendar-xmark fa-4x d-block text-center" />
                        )}
                    </MDBModalBody>
                    <MDBModalFooter className={data.length ? 'justify-content-between' : ''}>
                        {data.length ? (
                            <Pager total={total} search={search} onPage={setSearch} />
                        ) : null}
                        <MDBBtn type="button" color="secondary" onClick={setPosition.bind(null, null)}>{__('Close')}</MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    );
});
