import request, { CancelablePromise } from "../util/client";

export type ConfigResponse = { [path: string]: string };

export function getConfig(path: string | Array<string>) {
    let paths: Array<string> = [], result: Record<string, any> = {};
    for (let i of (Array.isArray(path) ? path : [path])) {
        result[i] = localStorage.getItem('config-' + i);
        if (result[i] === null) {
            delete result[i];
            paths.push(i);
        }
    }
    let p: CancelablePromise<ConfigResponse | string>;
    if (paths.length) {
        p = request<ConfigResponse>('config', 'GET', { path: typeof path === 'string' && path.includes('%') ? path : paths });
    } else {
        return new CancelablePromise<ConfigResponse>(resolve => resolve(result), () => { });
    }
    return new CancelablePromise<ConfigResponse>((resolve, reject) => {
        p.then(response => {
            if (typeof response === 'string') {
                reject();
            } else {
                for (let k in response) {
                    result[k] = response[k];
                    localStorage.setItem('config-' + k, response[k]);
                }
                resolve(result);
            }
        }, reject);
    }, p.doCancel);
}

export interface ConfigData {
    path: string;
    value: string;
}

export function saveConfig(params: ConfigData | Array<ConfigData>) {
    for (let i of (Array.isArray(params) ? params : [params])) {
        localStorage.removeItem('config-' + i.path);
    }
    return request<{ message: string }>('config', 'PUT', params);
}