import React from "react";
import { type Columns } from "../../component/Edit";
import useLocale from "../../util/i18n";
import { useCrumbs } from "../../component/breadcrumbs";
import Config from "./Config";
import { Helmet } from "react-helmet-async";
import languages from "../../source/language.json";
import currency from "../../source/currency.json";

export default function ConfigGeneral() {
    let [__] = useLocale();
    let [setCrumbs] = useCrumbs();
    let columns = React.useMemo<Columns>(() => ({
        [__('General')]: [
            {
                name: 'general/base_url',
                label: __('Base URL'),
                type: 'url',
                required: true
            },
            {
                name: 'currency/base',
                label: __('Base Currency'),
                type: 'select',
                required: true,
                options: currency
            },
            {
                name: 'currency/available',
                label: __('Available Currencies'),
                type: 'select',
                required: true,
                multiple: true,
                options: currency
            },
            {
                name: 'i18n/languages',
                label: __('Available Languages'),
                type: 'select',
                multiple: true,
                options: languages
            },
            {
                name: 'i18n/language_default',
                label: __('Default Language'),
                type: 'select',
                options: languages
            }
        ]
    }), [__]);
    React.useEffect(() => {
        setCrumbs(['General Config']);
    }, []);
    return (
        <>
            <Helmet>
                <title>{__('General Config')}</title>
            </Helmet>
            <Config columns={columns} />
        </>
    );
}